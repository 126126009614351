























































































































































































































import { UserState } from "@/common/store/user/types";
import { Component, Vue } from "vue-property-decorator";

import SavedDialog from "@/common/components/Dialogs/SavedDialog.vue";

import axios from "@/libs/axios/axios";
import { AxiosResponse, AxiosError } from "axios";
import { Action, Getter, State } from "vuex-class";

@Component({
  components: {
    SavedDialog
  }
})
export default class Settings extends Vue {
  @Action("logOff", { namespace: "login" }) logOff!: Function;
  @Action("setUserName", { namespace: "user" }) setUserName!: Function;
  @Getter("getUserName", { namespace: "user" }) userName!: string;
  @State("user") user!: UserState;

  email = "";
  name = "";
  notifyOnReminderAvailability = false;
  original = {
    email: "",
    name: "",
    notifyOnReminderAvailability: false
  };
  changePasswordVisible = false;
  oldPassword = "";
  newPassword = "";
  confirmNewPassword = "";
  changePasswordError = "";
  dialogSavedVisible = false;

  async mounted() {
    const response: void | AxiosResponse = await axios
      .get("profile")
      .catch((error: AxiosError) => {
        console.log(error);
      });

    if (response && response.data) {
      this.original.email = response.data.email;
      this.original.name = response.data.name;
      this.email = response.data.email;
      this.name = response.data.name;
    }

    const responseNotifications: void | AxiosResponse = await axios
      .get("settings/notifications")
      .catch((error: AxiosError) => {
        console.log(error);
      });

    if (responseNotifications && responseNotifications.data) {
      this.notifyOnReminderAvailability =
        responseNotifications.data.notifyOnReminderAvailability === 1;
      this.original.notifyOnReminderAvailability =
        responseNotifications.data.notifyOnReminderAvailability === 1;
    }
  }

  checkPermission(permission: string) {
    return this.user.permissions.includes(permission);
  }

  async saveProfile() {
    if (
      this.original.email !== this.email ||
      this.original.name !== this.name
    ) {
      await axios
        .put("profile", {
          name: this.name,
          ...(this.original.email !== this.email && { email: this.email })
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      this.original.email = this.email;
      this.original.name = this.name;
      this.setUserName(this.name);
    }
    if (
      this.original.notifyOnReminderAvailability !==
      this.notifyOnReminderAvailability
    ) {
      await axios
        .put("settings/notifications", {
          notifyOnReminderAvailability: this.notifyOnReminderAvailability
        })
        .catch((error: AxiosError) => {
          console.log(error);
        });

      this.original.notifyOnReminderAvailability = this.notifyOnReminderAvailability;
    }

    this.dialogSavedVisible = true;
  }

  async changePassword() {
    if (this.newPassword === "" || this.confirmNewPassword === "") {
      this.changePasswordError =
        "Je hebt één van de wachtwoorden niet ingevuld.";

      return false;
    } else if (this.newPassword !== this.confirmNewPassword) {
      this.changePasswordError =
        "Je nieuwe wachtwoord komt niet overeen met elkaar.";

      return false;
    } else {
      this.changePasswordError = "";
    }

    await axios
      .put("profile/change-password", {
        password: this.oldPassword,
        newPassword: this.newPassword
      })
      .catch((error: AxiosError) => {
        console.log(error);
      });

    this.handleChangePasswordClose();
    this.dialogSavedVisible = true;
  }

  handleChangePasswordClose() {
    this.changePasswordVisible = false;
  }

  handleSaveClose() {
    this.dialogSavedVisible = false;
  }

  get valuesAreChanged() {
    return (
      this.original.email !== this.email ||
      this.original.name !== this.name ||
      this.original.notifyOnReminderAvailability !==
        this.notifyOnReminderAvailability
    );
  }
}
